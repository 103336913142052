#Footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #eceff4;
  background-color: #2e3440;
  height: 25vh;
  padding: 1rem 1rem 2rem;
}

.Title {
  font-size: calc(20px + 1.8vmin);
}

.Subtitle {
  font-size: calc(12px + 1vmin);
}
