#Experience {
  background-color: #eceff4;
  color: #2e3440;
  margin-bottom: -1rem;
}

#Experience a {
  color: #506e93;
}

#Experience a:visited {
  color: #4c566a;
}

#Experience a:hover,
a:focus,
a:active {
  color: #4c566a;
}

.SectionTitle {
  margin: 0;
  padding-top: 2rem;
  font-size: 4rem;
}

.FilterControl {
  display: flex;
  justify-content: center;
  margin: 2rem 1rem 0rem;
  font-size: calc(20px + 0.6vw);
}

.FilterButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 2rem;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.FilterButton::after {
  content: attr(data-text);
  content: attr(data-text) / '';
  height: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  font-weight: 700;
}

.FilterControl button:hover,
.FilterControl button:focus,
.FilterControl .active {
  text-decoration: underline;
  outline: none;
  font-weight: 700;
}

.LinksContainer {
  display: flex;
  margin-top: 1rem;
}

.Subtitle {
  color: #434c5e;
}

.TimelineItemLink {
  margin-right: 0.75rem;
  font-weight: 600;
  padding: 0.4rem 0.5rem;
  text-decoration: none;
}

@media only screen and (max-device-width: 500px) {
  .FilterControl {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .FilterControl button {
    margin: 0;
  }
}

@media speech {
  .FilterButton::after {
    display: none;
  }
}
