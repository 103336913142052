#Header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eceff4;
  background-color: #2e3440;
  font-size: calc(10px + 2vmin);
  padding: 5rem 3rem;
}

#Resume {
  position: absolute;
  text-decoration: none;
  font-size: calc(10px + 1vmin);
  top: calc(15px + 1.05vmin);
  right: calc(15px + 1.05vmin);
  color: #eceff4;
  border: 1px solid #eceff4;
  border-radius: 0.25em;
  padding: 0.55rem 1rem;
  transition: background-color 200ms ease-in 0s, border-color 200ms ease-in 0s,
    color 200ms ease-in 0s;
}

#Resume:hover,
#Resume:focus {
  border-color: #88c0d0;
  color: #88c0d0;
}

.ImageContainer {
  padding-right: 7rem;
}

.Image {
  height: 32vh;
  width: 32vh;
  pointer-events: none;
  border-radius: 50%;
  border: 0.5rem solid #88c0d0;
}

hr {
  display: none;
}

.Summary {
  text-align: left;
  max-width: 75vmin;
}

.SocialLinks {
  margin-top: 7vh;
  padding: 0;
}

.Social {
  margin: 0 3vh;
  font-size: calc(16px + 3vmin);
  color: #a4a4a4;
  display: inline;
  text-decoration: none;
}

.Social:hover,
.Social:focus .Social:active {
  color: #88c0d0;
  cursor: pointer;
  transition: 0.2s;
}

.Social a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-device-width: 500px) {
  #Header {
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0;
  }

  .ImageContainer {
    padding: 4rem 0rem 3rem;
  }

  .Image {
    height: 55vw;
    width: 55vw;
  }

  hr {
    display: unset;
    position: absolute;
    min-width: 75%;
  }
}
